import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import WhyMND from "../../components/whyMND";
import MainLayout from "../../componentsTailwind/MainLayout";
import config from "../../configs";
import { CALCULATION_STANDARD_IMAGE_SUBTYPES, CALCULATOR_IMAGE_TYPES, SavingsCalculation } from "../../components/SavingsCalculation/SavingsCalculation";
import PhoneContactFormWrapper from "../../components/LeadForms/PhoneContactFormWrapper";
import ContactForm from "../../components/contactForm";
import { OFFER_TYPE } from "../../offers/offer-types";
import { CALCULATION_STYLES_DEFAULT_KEY, fillInStyles } from "../../components/SavingsCalculation/types/calculation-style-types";
import { CALCULATION_TYPE } from "../../calculation-type-constants";
import { FULL_COMMODITY_TYPE } from "../../commodity-constants";
import { MARKETING_SOURCE_ID_PARAM } from "../../lead-constants";
import { useContactFormOverlimitChange } from "../../components/ContactForm/hooks/use-contact-form-overlimit-change";
import { CALCULATION_RESULT_PRICE_OPTIONS } from "../../components/SavingsCalculation/constants/calculation-result-price-options";
import { CompaniesOfferBanner } from "../../components/companiesOfferBanner";
import { StandardOffers } from "../../componentsTailwind/Banners/StandardOffers";
import { COMPANIES_LARGE_URL } from "../../page-urls/page-urls";

const SOURCE_ID_GAS = 329;
const SOURCE_ID_ELECTRICITY = 330;

const ADDITIONAL_MARKETING_PARAMS = {
  [FULL_COMMODITY_TYPE.GAS]: `${MARKETING_SOURCE_ID_PARAM}${SOURCE_ID_GAS}`,
  [FULL_COMMODITY_TYPE.ELECTRICITY]: `${MARKETING_SOURCE_ID_PARAM}${SOURCE_ID_ELECTRICITY}`
};

const SMALLER_COMPANIES_SOURCE_ID = 331;

const bannerImageURL = require("../../images/firmy-a-podnikatele-mensi-firmy.png").default;
const bannerSmallImageURL = require("../../images/firmy-a-podnikatele-mensi-firmy-small.png").default;



export default ({ data }) =>
{
  const SEO = {
    title: "MND | Energie - plyn a elektřina za výhodné ceny",
    keywords: "dodavatel plynu, dodavatel elektřiny, dodavatel energií, srovnání cen plynu, srovnání cen elektřiny, cena plynu, cena elektřiny, změna dodavatele",
    description: "Získejte výhodnou cenu energií od stabilního a férového dodavatele elektřiny a plynu. Změna dodavatele energií jde s MND jednoduše online.",
  }

  const bannerData = data.mnd.hp.data.attributes.pumpJackBanner;
  const offers = data.mnd.offer.data.attributes.offers.filter(offer => offer.offerAssignment === OFFER_TYPE.SMB);
  const offersData = data.mnd.hp.data.attributes.fixedProducts;
  const fixedProductsBanner = data.mnd.hp.data.attributes.fixedProductsBanner;

  const webCalculator = data.mnd.webCalculator.data.attributes;

  const [consumption, setConsumption] = useState(null);
  const [commodity, setCommodity] = useState(null);
  const [consumptionHighTariff, setConsumptionHighTariff] = useState(null);

  const onResultChange = ({
    consumption,
    commodity,
    consumptionLowTariff,
    consumptionHighTariff,
  }) =>
  {
    setCommodity(commodity);
    setConsumption(consumption);

    if (consumptionLowTariff)
    {
      setConsumption(consumptionLowTariff);
    }
    if (consumptionHighTariff)
    {
      setConsumptionHighTariff(consumptionHighTariff);
    }
  }

  const { handleFormOverlimitCalculationSubmit } = useContactFormOverlimitChange({
    targetUrl: COMPANIES_LARGE_URL,
  });

  const webCalculatorOptions = {
    form: {
      title: "Spočítejte si platby pro firmu",

      locationRadio: {
        hidden: true,
      },


      errorMessages: {
        eleInvalidText: {
          value: undefined
        },
        gasInvalidText: {
          value: undefined
        },
      },

      allowCommodityOverlimit: true,
    },
    result: {
      arrangeButton: {
        offerAdditionalParams: ADDITIONAL_MARKETING_PARAMS,
      },

      priceType: CALCULATION_RESULT_PRICE_OPTIONS.VAT_COMPARISON
    },
    initialData: {
      type: CALCULATION_TYPE.COMPANY,
    },
    callbacks: {
      onCalculationSubmit: handleFormOverlimitCalculationSubmit
    }
  }

  const bannerTitleP1 = "Nabídka";
  const bannerTitleP2 = "pro menší firmy";
  const bannerTitleFull = `${bannerTitleP1} ${bannerTitleP2}`;
  const bannerTitle = <>{bannerTitleP1}<br className="inline md:hidden" /><span className="hidden sm:inline">{" "}</span>{bannerTitleP2}</>;


  const renderAfterBanner = () => <StandardOffers
    offers={offers}
    bottomLinkList={offersData.bottomLinkList}
    additionalParams={ADDITIONAL_MARKETING_PARAMS}
    showPriceWithoutTax
    textBottom={offersData.textBottom}

    customStyles={{
      wrapper: {
        additive: "px-[0px]"
      }
    }}
  />;

  return (
    <MainLayout
      headerConfig={{
        topPanel: true,
        mainPanel: true,
      }}
    >
      <Helmet>
        <title>{SEO.title}</title>
        <meta name="keywords" content={SEO.keywords} />
        <meta name="description" content={SEO.description} />
        <meta name="og:description" content={SEO.description} />
        <meta property="og:image" content={`${config.serverUrl}/images/og-image-hp.png`} />
        <script>{`
          var gaAdditionalPageData = JSON.stringify({ type: 'Homepage', section: 'Gas | Electricity' });
        `}</script>
        <link rel="canonical" href="https://www.mnd.cz/"></link>
      </Helmet>
      {/*<FixedProductsBanner
        title={bannerData.title}
        text={bannerData.text}
        buttonTitle={bannerData.buttonTitle}
        buttonLink={bannerData.buttonLink}
        textBottom={offersData.textBottom}
        textList={offersData.textList}
        fixedProductsBanner={{
          ...fixedProductsBanner

        }}
        showBreadcrumbs={true}
        bannerImageURL={bannerImg}
        bannerSmallImageURL={bannerImgSmall}
        smallImageCustomAdditiveClass="object-[0px_0px]"
        customStyles={{
          additive: {
            wrapper: "pb-0"
          }
        }}
      >
        <Heading as="h1" mt={"25px"} fontSize={["26px", "38px"]} fontWeight="700" color="white.100" textDecoration="none" mb={"20px"}>
          {customBannerData.title}
        </Heading>
        <Text color="white.100" fontSize={"18px"} lineHeight={"24px"} fontWeight={400} textDecoration="none" mb={"15px"}>
          {customBannerData.consumptionText}
        </Text>
        <Text color="white.100" fontSize={"18px"} lineHeight={"24px"} fontWeight={400} textDecoration="none" mb={"10px"}>
          <Image src={require("../../images/gas-light.svg").default} loading="lazy" height={"24px"} mr={"5px"} position={"relative"} verticalAlign={"text-bottom"} />
          {customBannerData.gasConsumption}
        </Text>
        <Text color="white.100" fontSize={"18px"} lineHeight={"24px"} fontWeight={400} textDecoration="none">
          <Image src={require("../../images/electricity-light.svg").default} loading="lazy" height={"24px"} mr={"5px"} position={"relative"} verticalAlign={"text-bottom"} />
          {customBannerData.eleConsumption}
        </Text>
      </FixedProductsBanner>
      */}

      <CompaniesOfferBanner
        title={bannerTitle}
        breadcrumbsName={bannerTitleFull}
        gasConsumptionText="Plyn do 63 MWh/rok"
        eleConsumptionText="Elektřina do 30 MWh/rok"
        renderAfterBanner={renderAfterBanner}

        bannerImageURL={bannerImageURL}
        bannerSmallImageURL={bannerSmallImageURL}

        consumptionBlur

        customStyles={{
          breadcrumbs: {
            wrapper: {
              additive: `left-[20px]`,
            }
          },

          content: {
            wrapper: {
              additive: `px-[20px]`
            }
          }
        }}
      />

      <div className="bg-resource-green-100">
        <SavingsCalculation
          offers={offers}
          data={{ ...webCalculator, bottomResultText: "Chci poradit", }}
          options={webCalculatorOptions}
          onResultChange={onResultChange}
          analyticsData={{ section: "SMB" }}
          calculatorStyles={fillInStyles({ styleTypes: { input: CALCULATION_STYLES_DEFAULT_KEY } })}
          calculatorImage={{ url: require('../../images/savings-calculator.png').default, type: CALCULATOR_IMAGE_TYPES.NORMAL, subtype: CALCULATION_STANDARD_IMAGE_SUBTYPES.STANDARD }}
          customStyleOverrides={{
            additive: {
              wrapperPadding: "py-10"
            }
          }}
        />
      </div>

      <PhoneContactFormWrapper>
        <ContactForm
          enableEmail={false}
          type="PAGE_HP"
          commodity={commodity}
          consumption={consumption * 1000}
          consumptionHighTariff={consumptionHighTariff * 1000}
          analytics={{
            type: "Lead",
            text: "Ozveme se vám",
            section: "SMB",
            sourceId: SMALLER_COMPANIES_SOURCE_ID,
          }}
          leadsource={SMALLER_COMPANIES_SOURCE_ID}
          enableCallbackTime
        />
      </PhoneContactFormWrapper>

      <div>
        <WhyMND bg={"white.100"} />
      </div>
    </MainLayout>
  );
};

export const query = graphql`
        query FaPQuery {
          mnd {
          hp {
          data {
          attributes {
          SEO {
          title
              description
        keywords
            }
        articles {
          articleID
        }
        pumpJackBanner {
          title
              text
        buttonTitle
        buttonLink
            }
        HowTransitionWork {
          title
              faqITems {
          title
                text
              }
            }
        fixedProducts {
          textBottom
              bottomLinkList {
          content
                icon
              }
            }
        questionArticles {
          title
              linkText
        href
        icon
            }
        fixedProductsBanner {
          largeImage {
          data {
          attributes {
          url
        }
                }
              }
        smallImage {
          data {
          attributes {
          url
        }
                }
              }
            }
          }
        }
      }

        ...WebCalculator

        ...FullOffers
    }
  }
        `;
