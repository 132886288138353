import React, { useState } from "react";
import { Text } from "../../style/Text";
import { Modal } from "../Modal";
import { ArrowSmDownIcon, XIcon } from "@heroicons/react/solid";
import { Button } from "../../style/Button";
import config from "../../configs";
import { OF_FIRST_STEP_SLUG } from "../../constants";

const callbackOrDownload = (download, callback) => {
  if (window?.innerWidth < 500) {
    window.location.href = download;
  } else {
    callback();
  }
};

/**
 * Komponenta, která představuje textový odkaz na PDF soubor.
 * Na mobilu se pdf rovnou stáhne.
 * Na větších zařízeních jako desktop apod. se po kliknutí na odkaz otevře PDF v modalu.
 */
const PdfViewer = ({ commodity, offerCode, linkText, srcURL, useImage = false, linkAS }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const commoditySublink = commodity === "electricity" ? "elektrina" : "plyn";

  const offerLinked = `${config.gofu}${commoditySublink}/${OF_FIRST_STEP_SLUG}?product=${offerCode}`;

  return (
    <Text
      as={linkAS}
      color="expertiseGray"
      mb={"3px"}
      // className={"newHP gap-1 text-center text-small underline hover:no-underline cursor-pointer"}
      className={"items-center underline hover:no-underline cursor-pointer"}
      display={"inline-flex"}
      onClick={() => callbackOrDownload(srcURL, () => setModalOpen(true))}
    >
      <Text color={"exploratoryGreen"} fontWeight={400} lineHeight={"22px"}>
        {linkText}
      </Text>

      {useImage && <img src={require("../../images/pdf.png").default} width="20px" height="20px" alt="Cenik" className="mb-0 ml-1" />}

      <Modal onClose={setModalOpen} show={modalOpen}>
        <div className="inline-block align-bottom bg-white px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle max-w-screen-lg w-full sm:p-6">
          <div className="flex justify-end leading-none">
            <button type="button" className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none" onClick={() => setModalOpen(false)}>
              <span className="sr-only">Zavřít</span>
              <XIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>

          <div className={"mt-4"}>
            <iframe src={srcURL} frameBorder="0" title={linkText} className={"w-full"} style={{ height: "70vh" }} />
          </div>

          <div className="flex flex-col-reverse sm:flex-row mt-4 items-center sm:justify-end">
            <a
              href={srcURL}
              target="_blank"
              className={"flex items-center font-bold text-exploratory-green-400 sm:mr-8 mt-4 sm:mt-0 hover:no-underline"}
              data-analytics="download"
              data-name={linkText}
              data-type="Pricelist"
              data-area="TopOffer"
              data-format=".pdf"
            >
              <ArrowSmDownIcon className="h-6 w-6 mr-2" aria-hidden="true" /> Stáhnout ceník
            </a>
            <Button as={"a"} href={offerLinked}>
              Sjednat online
            </Button>
          </div>
        </div>
      </Modal>
    </Text>
  );
};

export default PdfViewer;
